<template>
  <Form @submit="submit">
    <div v-if="isHuntInvitation" class="notification is-primary push-top">
      <span v-html="$t('auth.join.accountRequiredToAnswerInvite')"></span>
    </div>

    <FormField :label="$t('auth.firstName')">
      <TextInputField v-model="userDetails.firstName" :disabled="submitting" />
    </FormField>

    <FormField :label="$t('auth.lastName')">
      <TextInputField v-model="userDetails.lastName" :disabled="submitting" />
    </FormField>

    <FormField :label="$t('general.email')">
      <EmailInputField v-model="userDetails.email" :disabled="submitting || disableEmail" />
      <HelpText v-if="isEmailOccupied" type="danger" :text="$t('auth.join.emailOccupied')" />
    </FormField>

    <FormField :label="$t('general.password')">
      <PasswordInputField v-model="userDetails.password" :disabled="submitting" />
    </FormField>

    <FormField :label="$t('auth.repeatPassword')">
      <PasswordInputField v-model="userDetails.repassword" :disabled="submitting" />
      <HelpText v-if="passwordMismatch" type="danger" :text="$t('auth.join.passNoMatch')" />
    </FormField>

    <Button
      type="secondary"
      :title="$t('general.signup')"
      :disabled="!isValid"
      :loading="submitting"
      fullwidth
      @click="submit">
    </Button>
  </Form>
</template>

<script>
import * as _ from '@/assets/libs/utils/'

export default {
  props: {
    userDetails: {
      type: Object,
      default: () => {}
    },

    isHuntInvitation: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      submitting: false,
      isEmailOccupied: false,
      disableEmail: this.userDetails.email.length > 0
    }
  },

  computed: {
    isFirstNameValid () {
      return this.userDetails.firstName.length >= 1 && !this.hasNumber(this.firstName)
    },

    isLastNameValid () {
      return this.userDetails.lastName.length >= 1 && !this.hasNumber(this.lastName)
    },

    isEmailValid () {
      return this.userDetails.email.length && _.isEmail(this.userDetails.email) && _.validateEmail(this.userDetails.email)
    },

    isPasswordValid () {
      return this.userDetails.password.length >= 6
    },

    isRePasswordValid () {
      return this.userDetails.repassword.length >= 6
    },

    arePasswordsMatching () {
      return this.userDetails.password === this.userDetails.repassword
    },

    arePasswordsValid () {
      return this.isPasswordValid && this.isRePasswordValid && this.arePasswordsMatching
    },

    isValid () {
      return this.isFirstNameValid && this.isLastNameValid && this.isEmailValid && this.arePasswordsValid
    },

    passwordMismatch () {
      if (this.userDetails.password.length > 0 && this.userDetails.repassword.length > 0) {
        return this.userDetails.password !== this.userDetails.repassword
      }

      return false
    }
  },

  methods: {
    hasNumber (string) {
      return /\d/.test(string)
    },

    async submit () {
      this.submitting = true
      this.isEmailOccupied = false

      try {
        await this.$store.dispatch('auth/join', this.userDetails)
        this.$emit('registration-complete')
      } catch (error) {
        if (error.status === 400) {
          this.isEmailOccupied = true
        } else {
          this.$notification.danger(this.$i18n.t('auth.join.unknownErrorMessage'))
        }
      }

      this.submitting = false
    }
  }
}
</script>
