<template>
  <div>
    <AuthHeader :title="headerTitle" />

    <JoinRegisterForm
      v-if="status === ''"
      :user-details="userDetails"
      :is-hunt-invitation="isHuntInvitation"
      @registration-complete="onRegistrationComplete" />

    <AuthFooter v-if="status === ''" />

    <div v-if="status === 'success'" class="content">
      <p v-html="$t('auth.join.welcomeToWeHunt', { firstName: userDetails.firstName })"></p>
      <p>{{ $t('auth.join.downloadAppReminder') }}</p>

      <JoinSignInButton
        :email="userDetails.email"
        :password="userDetails.password"
        :invitation="isHuntInvitation" />
    </div>

    <div class="confetti-wrap" />
  </div>
</template>

<script>
import AuthHeader from '@/modules/auth/components/AuthHeader.vue'
import AuthFooter from '@/modules/auth/components/AuthFooter.vue'
import JoinSignInButton from '@/modules/auth/components/JoinSignInButton.vue'
import JoinRegisterForm from '@/modules/auth/components/JoinRegisterForm.vue'

import confetti from '@/assets/libs/confetti/'

export default {
  components: {
    AuthHeader,
    AuthFooter,
    JoinRegisterForm,
    JoinSignInButton
  },

  data () {
    return {
      userDetails: {
        firstName: '',
        lastName: '',
        email: this.$route.query.email || '',
        password: '',
        repassword: '',
        phoneNumber: null
      },

      status: ''
    }
  },

  computed: {
    isHuntInvitation () {
      return Boolean(this.$route.query.invitation && this.$route.query.invitation === 'hunt' && this.$route.query.email && this.$route.query.token)
    },

    headerTitle () {
      return this.status === 'success' ? this.$i18n.t('general.welcome') : this.$i18n.t('general.signup')
    }
  },

  methods: {
    onRegistrationComplete () {
      this.status = 'success'
      this.triggerConfetti()
    },

    triggerConfetti () {
      const element = this.$el.querySelector('.confetti-wrap')
      return confetti(element)
    }
  }
}
</script>

<style lang="scss" scoped>
.confetti-wrap {
  transform: translate(-50%, -50%);
  pointer-events: none;
  position: absolute;
  bottom: 50%;
  left: 50%;
}
</style>
