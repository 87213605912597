import { render, staticRenderFns } from "./JoinPage.vue?vue&type=template&id=796f3891&scoped=true&"
import script from "./JoinPage.vue?vue&type=script&lang=js&"
export * from "./JoinPage.vue?vue&type=script&lang=js&"
import style0 from "./JoinPage.vue?vue&type=style&index=0&id=796f3891&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "796f3891",
  null
  
)

export default component.exports