<template>
  <Button
    type="secondary"
    :title="title()"
    :loading="isAuthenticating"
    fullwidth
    @click="signIn">
  </Button>
</template>

<script>
export default {
  props: {
    invitation: {
      type: Boolean,
      default: false
    },

    email: {
      type: String,
      default: ''
    },

    password: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      isAuthenticating: false
    }
  },

  methods: {
    title () {
      return this.invitation ? this.$i18n.t('auth.join.signInAndAnswerInvitation') : this.$i18n.t('general.signin')
    },

    async signIn () {
      this.isAuthenticating = true

      await this.$store.dispatch('auth/signIn', {
        email: this.email,
        password: this.password,
        remember: false
      })

      const url = this.invitation ? `invite/${this.$route.query.token}/hunt` : '/'
      this.$router.push(url)
    }
  }
}
</script>
